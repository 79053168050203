import * as React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import { graphql, Link } from "gatsby"
/** @jsx jsx */
import { Link as LinkThemed, jsx } from "theme-ui"

export default function IndexPage ({ data }) {
  const posts = data.blog.posts;

  return (
    <Layout>
      <SEO title="Home" />
      <section>
        <section sx={{
          display: 'flex',
          alignItems: 'baseline',
          justifyContent: 'space-between',
          flexWrap: 'wrap'
        }}>
          <h1>
            
            Últimas postagens
          </h1>
          <h2 sx={{ fontWeight: 'bold' }}>
            <LinkThemed variant="third" as={Link} to="/blog">
              Ver mais
            </LinkThemed>
          </h2>
        </section>
        {posts.map((post) => (
          <section key={post.id}>
            <div >
              <LinkThemed variant="secondary" as={Link} to={post.fields.slug}>
                  {post.frontmatter.title}
                  <small sx={{ }}> - { post.frontmatter.date }</small>
              </LinkThemed>
            </div>
          </section>
        ))}
      </section>
    </Layout>
  )
}

export const postsQuery = graphql`
  {
    blog: allMdx(
      sort: {order: DESC, fields: frontmatter___date}
      limit: 5
    ) {
      posts: nodes {
        id
        fields {
          slug
        }
        frontmatter {
          tags
          title
          date(locale: "PT", formatString: "DD/MM/YYYY")
          author
        }
      }
    }
  }
`